import { render, staticRenderFns } from "./Mains.vue?vue&type=template&id=7052a12e&scoped=true&"
import script from "./Mains.vue?vue&type=script&lang=js&"
export * from "./Mains.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7052a12e",
  null
  
)

export default component.exports